<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">当前：发票一览</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :sm="12" :md="4">
                                <el-date-picker
                                        style=" margin-top: 8px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        ref="pickerDate"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :sm="12" :md="5">
                                <el-button style=" margin-top: 8px; " type="primary" round @click="search()"
                                           size="mini">搜索
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()"
                                           style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>
                                <vxe-toolbar class="pull-left" style=" height: 42px; margin-left: 12px" custom export
                                             ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>

                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                show-footer
                                ref="xGrid"
                                align="right"
                                row-id="id"
                                :footer-method="footerMethod"
                                highlight-current-row
                                size="mini"
                                :height="(this.getViewHeight() - 220)+''"
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :cell-class-name="cellClassName"
                                @cell-click="cellSelectEvent"
                                @cell-dblclick="cellClickEvent"
                                :mouse-config="{selected: true}"
                                :edit-config="{showIcon:false,trigger: 'dblclick', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">

                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,2500,5000,50000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>

                <vxe-modal v-model="showModel.isShow" width="600" :title="showModel.bill_id">
                    <template #default>
                        <vxe-grid
                                ref="modalGrid"
                                show-overflow
                                auto-resize
                                height="300"
                                size="mini"
                                highlight-current-row
                                :data="tableDataModel"
                                :columns="tableColumnModel">
                        </vxe-grid>
                    </template>
                </vxe-modal>

            </el-form>
        </el-main>
    </div>
</template>


<script>
    import {filterRender} from "@/utils/gird-filter";

    export default {

        name: "InvoiceAll",
        data() {
            return {
                is_invoice_bill:false,
                showModel: {
                    isShow: false,//显示model层
                    bill_id: '',//显示model层
                },
                invoiceSaveList: [],//开票的数组
                invoice_bill_id: '',//开票的ID用来绑定数组
                before_balance_company:'',
                end_balance_company:'',
                pickerDate: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 90), new Date()],
                formData:{
                    company_uuid :'',
                    company_name :'',
                    bill_date: new Date(),//开单日期
                    total_amount: 0,//所选进金额
                    total_balance_money : 0,//所选多(少)开票金额
                    invoice_money: 0,//开票金额
                    remark: '',//开票备注
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                tableDataModel: [],
                tableColumnModel: [
                    {
                        field:'is_cancel', title: '状态', width: 80, formatter: ({cellValue}) => {
                            if (cellValue) {
                                return '已作废'
                            } else {
                                return '正常'
                            }
                        }
                    },
                    {field:'only_bill_id', title: '单号', width: 120},
                    {field:'total_amount', width: 150, title: '金额'},
                    {field:'registrar', width: 150, visible: true, title: '创建人'},
                    {field:'only_bill_date', width: 150, visible: true, title: '创建日期'},
                    {field:'last_name', width: 150, visible: true, title: '最后修改人'},
                    {field:'last_date', width: 150, visible: true, title: '最后修改日期'},
                ],
                tableColumn: [
                    {
                        field:'id', width: 80, title: '唯一ID', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/openInvoiceAll/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'bill_date', width: 70, title: '日期', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/openInvoiceAll/searchHeard', this.pickerDate,this.formData)
                       },formatter: ({cellValue}) => {return this.formatDate(cellValue,'yyyy-MM-dd')}
                   }, {
                        field:'bill_id_list', width: 220, title: '关联单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/openInvoiceAll/searchHeard', this.pickerDate,this.formData)
                       }
                   },
                   {
                        field:'remark', width: 420, title: '备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/openInvoiceAll/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field:'invoice_money', width: 90, title: '开票金额', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/openInvoiceAll/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field:'registrar', width: 90, title: '记录员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/openInvoiceAll/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field:'create_date', width: 120, title: '创建日期', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/openInvoiceAll/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field:'last_name', width: 90, title: '最后修改人', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/openInvoiceAll/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                    {
                        field:'last_date', width: 120, title: '最后修改日期', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/openInvoiceAll/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                ],
                tableDataMain: [],
                filterData: {},
                sortData: {},
           }
       },
        methods: {
            cellClassName({row,column}) {
                if (this.$refs.xGrid.isCheckedByCheckboxRow(row)) {
                    return 'green-row';
                } else if (column.property == 'bill_id' && (row.type_name == '销售开单' || row.type_name == '发票单')) {
                    return 'invoice-col-blue';
                } else if (row.type_name == '销售开单' && (column.property == 'has_invoice' || column.property == 'invoice_remark_kd')) {
                    return 'col-fail';
                }else if (row.type_name == '收款单') {
                    return 'col-success';
                } else if (row.type_name == '发票单') {
                    return 'col-yellow';
                }
            },
            //选中一行
            cellSelectEvent({row, rowIndex}) {
                if (this.$refs.xGrid.isCheckedByCheckboxRow(row)) {
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], false)
                    this.$refs.xGrid.setCurrentRow(null);
                } else {
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], true)
                }

                //this.$refs.xGrid.setCurrentRow(row);
                //console.log(this.$refs.xGrid.getCurrentRecord())
                this.getSelectRecords();
                if (this.$refs.xGrid.getCurrentRecord() != null && this.$refs.xGrid.getCurrentRecord() != 'undefined' && this.$refs.xGrid.getCurrentRecord().type_name == '发票单'){
                    console.log("进入发票单")
                    let row = this.$refs.xGrid.getCurrentRecord();
                    this.is_invoice_bill = true;
                    this.invoice_bill_id = row.bill_id;
                    this.formData.remark = row.invoice_remark;
                    this.formData.total_amount = row.in_money;
                    this.formData.invoice_money = row.invoice_money;
                    this.formData.total_balance_money = row.total_balance_money;
                }else{
                    //console.log("没有进入发票单")
                    this.invoice_bill_id = this.invoiceSaveList.toString();
                    this.is_invoice_bill = false;
                    this.formData.remark = '';
                    this.formData.invoice_money = '';
                }
            },
            searchModalId(){
                this.$axios({
                    method: 'post',
                    url: '/admin/invoice/searchCashTypeId',
                    data: {
                        bill_id:this.showModel.bill_id,
                    }
                }).then((response) => {//这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log('response', response)
                    //console.log('response.data.tableData',response.data.tableData)
                    if (response.status == 200 && response.data.state == "ok") {
                        if (this.isBlankVue(response.data.list)) {
                            this.tableDataModel = [];
                        } else {
                            this.tableDataModel = response.data.list
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            //双击勾单
            cellClickEvent({row, column}) {
                console.log(column.property);
                console.log(row.type_name);
               if ((row.type_name == '发票单' && column.property == 'bill_id') || (row.type_name == '销售开单' && column.property == 'bill_id')) {
                    this.showModel.isShow = true;
                    this.showModel.bill_id = row.bill_id;
                    //查询单价
                    this.searchModalId();
                }else if (row.type_name == '销售开单' && column.property == 'has_invoice') {
                    let b = true;
                    if (row.has_invoice == '已开'){
                        //已开，则取反值
                        b = false;
                    }
                    this.$axios({
                        method: 'post',
                        url: '/admin/invoice/hasInvoice',
                        data: {
                            only_bill_id: row.bill_id,
                            has_invoice: b
                        }
                    }).then((response) => {          //这里使用了ES6的语法
                        console.log(response)       //请求成功返回的数据
                        if (response.status === 200 && response.data.state == 'ok') {
                            this.$message({
                                showClose: true,
                                message: '设置成功！',
                                type: 'success'
                            });
                            this.search();
                        } else {
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                            });
                            this.search();
                            return
                        }
                    }).catch((error) => {
                        console.log(error)
                    });
                }
            },
            search() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method: 'POST',
                    url: '/admin/openInvoiceAll/search',
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                        formData: this.formData
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.before_balance_company = response.data.before_balance_company
                        this.end_balance_company = response.data.end_balance_company
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.search();
           },

            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                return count;
           },
            getSelectRecords() {
                this.invoiceSaveList = [];
                if (this.$refs.xGrid.getCheckboxRecords().length > 0) {
                    for (let i = 0; i < this.$refs.xGrid.getCheckboxRecords().length; i++) {
                        if ("销售开单" == this.$refs.xGrid.getCheckboxRecords()[i].type_name) {
                            this.invoiceSaveList.push(this.$refs.xGrid.getCheckboxRecords()[i].bill_id);
                        }
                    }
                }
            },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (
                            column.property === 'before_count' || column.property === 'before_stock_amount' || column.property === 'before_weighing_heavy_total'
                            || column.property === 'now_single_count' || column.property === 'now_naked_price' || column.property === 'now_weighing_heavy'
                            || column.property === 'now_single_count_kd' || column.property === 'now_stock_amount_kd_total' || column.property === 'now_weighing_heavy_kd'
                            || column.property === 'total_count' || column.property === 'total_stock_amount' || column.property === 'total_weighing_heavy_total'
                            || column.property === 'in_money' || column.property === 'out_money'|| column.property === 'invoice_money'
                        ) {
                            sums.push(this.sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },

       },
        created() {
            this.$nextTick(() => {
                this.loading = true
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                this.search();
           })
       }
   }
</script>

<style scoped>
    /deep/ .vxe-body--row {
        font-size: 12px !important;
    }
</style>
